import React from "react"
import styled from "styled-components"

const Container = ({ children }) => {
  return <ContainerWrapper>{children}</ContainerWrapper>
}

const ContainerWrapper = styled.div`
  padding-top: 100px;
  max-width: 940px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px auto 2em;
`

export default Container
