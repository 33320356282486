import React from "react"
import styled from "styled-components"

const Footer = () => {
  return (
    <FooterWrapper>
      <div className="container">
        <div className="copyright">
          &copy; {new Date().getFullYear()}-present Subhash Bhushan. All Rights
          Reserved.
        </div>
      </div>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  margin-top: auto;
  background: var(--mainBlack);
  padding: 1rem;
  color: var(--mainWhite);

  .container {
    max-width: 940px;
    align-items: center;
    margin: 0px auto;
  }

  .links a {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--mainWhite);
    margin: 0.5rem 1rem;
    letter-spacing: var(--mainSpacing);
    transition: var(--mainTransition);
    font-weight: bold;
  }
  .links a:hover {
    color: var(--primaryColor);
  }
  .icons a {
    display: inline-block;
    margin: 1rem;
    font-size: 1.3rem;
    color: var(--mainWhite);
    transition: var(--mainTransition);
  }
  .icons a:hover {
    color: var(--primaryColor);
  }
  .copyright {
    line-height: 2;
    font-size: 0.8rem;
  }

  @media screen and (min-width: 940px) {
    .copyright {
      padding-left: 0.25rem;
    }
  }
`

export default Footer
