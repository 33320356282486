import React, { useState } from "react"
import links from "../constants/links"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { FaAlignRight } from "react-icons/fa"
import styled from "styled-components"

const Navbar = () => {
  const [isOpen, setNav] = useState(false)
  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  return (
    <header>
      <NavWrapper>
        <div className="container">
          <div className="nav-center">
            <div className="nav-header">
              <AniLink fade to="/">
                <img src="/logo.png" className="logo-image" alt="logo" />
              </AniLink>
              <button type="button" className="logo-btn" onClick={toggleNav}>
                <FaAlignRight className="logo-icon" />
              </button>
            </div>
            <ul className={isOpen ? "nav-links show-nav" : "nav-links"}>
              {links.map((item, index) => {
                return (
                  <li key={index}>
                    <AniLink fade to={item.path} activeClassName="active">
                      {item.text}
                    </AniLink>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </NavWrapper>
    </header>
  )
}

const NavWrapper = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  background-color: #f7f6f6;

  .container {
    max-width: 940px;
    align-items: center;
    margin: 0px auto;
  }

  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    h1 {
      margin-bottom: 0;
    }
  }

  .logo-image {
    width: 32px;
    height: 32px;
  }

  .logo-btn {
    background: transparent;
    border: none;
    outline: none;
  }
  .logo-btn:hover {
    cursor: pointer;
  }
  .logo-icon {
    color: var(--primaryColor);
    font-size: 1.5rem;
  }

  .nav-links {
    list-style-type: none;
    transition: var(--mainTransition);
    height: 0;
    overflow: hidden;
  }
  .show-nav {
    height: 350px;
  }
  .nav-links a {
    display: block;
    padding: 1rem 0 1rem 4rem;
    text-decoration: none;
    text-transform: capitalize;
    color: var(--mainBlack);
    transition: var(--mainTransition);
    font-weight: bold;
    letter-spacing: var(--mainSpacing);
  }
  .nav-links a.active {
    color: var(--primaryColor);
  }
  .nav-links a:hover {
    color: var(--primaryColor);
  }
  .nav-social-links {
    display: none;
  }

  @media screen and (min-width: 992px) {
    .logo-btn {
      display: none;
    }
    .nav-center {
      max-width: 1230px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .nav-links {
      height: auto;
      display: flex;
    }
    .nav-social-links {
      display: flex;
      line-height: 0;
    }
    .nav-social-links a {
      color: var(--primaryColor);
      margin: 0 0.5rem;
      font-size: 1.2rem;
      transition: var(--mainTransition);
    }
    .nav-social-links a:hover {
      color: var(--mainBlack);
      transform: translateY(-5px);
    }
  }
`

export default Navbar
