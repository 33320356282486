import React from "react"
import Container from "./Container"
import Navbar from "./Navbar"
import Footer from "./Footer"
import styled from "styled-components"

const Layout = ({ children }) => {
  return (
    <MainWrapper>
      <Navbar />
      <Container>{children}</Container>
      <Footer />
    </MainWrapper>
  )
}

const MainWrapper = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  font-family: var(--bodyFont);
  color: var(--mainBlack);
  background: var(--mainWhite);
  line-height: 1.8;
  font-size: 1rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--headerFont);
    margin-bottom: 1.25rem;
    letter-spacing: var(--mainSpacing);
  }
  h1 {
    font-size: 3.625rem;
  }
  h2 {
    font-size: 2.03rem;
  }
  h3 {
    font-size: 1.3em;
  }
  p {
    margin: 1rem 0;
  }
  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
    text-decoration: underline;
  }
  a:hover {
    color: var(--primaryColor);
    text-decoration: underline;
  }
  ul li {
    padding-left: 0;
  }
  .btn-white,
  .btn-primary {
    text-transform: uppercase;
    letter-spacing: var(--mainSpacing);
    color: var(--mainWhite);
    border: 2px solid var(--mainWhite);
    padding: 0.9rem 1.6rem;
    display: inline-block;
    transition: var(--mainTransition);
    cursor: pointer;
  }
  .btn-white:hover {
    background: var(--mainWhite);
    color: var(--primaryColor);
  }
  .btn-primary {
    background: var(--primaryColor);
    color: var(--mainWhite);
    border: 2px solid var(--primaryColor);
  }
  .btn-primary:hover {
    background: transparent;
    color: var(--primaryColor);
  }
`

export default Layout
