export default [
  {
    path: "/posts",
    text: "Blog",
  },
  {
    path: "/notes",
    text: "Book Notes",
  },
  {
    path: "/garden",
    text: "Garden",
  },
]
